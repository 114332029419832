
export default {
    user: {
        password: [
            (value) => !!value || "Required",
            (value) => (value && value.length >= 6) || "Minimum 6 characters",
        ],
        email: [
            v => !!v || 'Required',
            v => /.+@.+\..+/.test(v) || 'Invalid e-mail address',
            // TODO: Fix better email validation
        ],
    },
}